@media only screen and (max-width: 820px) {
    .section {
        padding: 2rem 0.5rem;
    }

    .logo {
        width: 100%;
    }

    .head-card {
        position: relative;
        width: 100%;
    }

    .mnone {
        display: none !important;
    }

    .text-team {
        transform: none;
        transform-origin: 0rem 0rem !important;
    }

    .mobiles_ img {
        margin-top: 0;
        position: relative;
        right: 0;
    }

    .footer-menu ul {
        display: block;
        line-height: 2.5rem;
    }

    .login-form {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .login-form-button {
        width: 100%;
    }

    .verification .ant-input {
        height: 65px !important;
        width: 55px !important;
    }

    /* ////////spacing */

    .xs-plr-1 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .xs-mt-0 {
        margin-top: 0rem !important;
    }

    .ant-layout-content {
        margin: 0 !important;
        padding: 1.5rem !important;
    }

    .mp0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .pt0 {
        padding-top: 0 !important;        
    }

    .app-membership .event-tab .ant-tabs-tab {
        padding: 5px 0.5rem !important;
    }

    .app-membership .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 8px !important;
    }

    .app-membership .event-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 1rem !important;
    }

    .app-membership .event-tab .ant-tabs-tab-btn {
        font-size: 1rem !important;
    }

    .app-membership .ant-card {
        width: 100% !important;
    }

    .site-layout {
        margin-left: 0 !important;
    }

    .ant-layout-sider {
        /* display: none; */
    }
    .card-cover {
        height: 180px!important;
    }
    .cont-btn{
        width:"100%!important"
    }
    .event-tab .ant-tabs-tab {
        padding: 12px 1rem;
      }
      .people-list ul {
        height: auto!important;
      }
      .subtab .ant-tabs-tab{
        padding: 12px 0.5rem !important;
        text-align: center;
        justify-content: center;
      }
      .appmambership .ant-card-body{
          /* padding-right: 0; */
      }
      .event-tab .ant-tabs-tab-btn {
        font-size: 16px !important;
      }
      .ant-popover {
        width: 80% !important;
      }
}

@media screen and (max-width: 1400px) and (min-width: 820px) {
    .event-tab .ant-tabs-tab {
        padding: 12px 8rem!important;
    }
    .rccs{
        width: 100% !important;
    }
    .rccs__card{
        width: 100% !important;
    }
}

@media screen and (max-width: 2500px) and (min-width: 820px) {
    .dnone {
        display: none!important;
    }
}


@media all and (device-width: 1080px) and (device-height: 820px) and (orientation:landscape) {
    .subs-layout{
        padding: 3rem 0rem!important;
        margin:0!important;
    }
    .subscription{
        padding: 0!important;
    }
  }


  /* /////////for ipad */

  @supports (-webkit-touch-callout: none) {
    .section {
        padding: 2rem 0.5rem;
    }

    .logo {
        width: 100%;
    }

    .head-card {
        position: relative;
        width: 100%;
    }

    .mnone {
        display: none !important;
    }

    .text-team {
        transform: none;
        transform-origin: 0rem 0rem !important;
    }

    .mobiles_ img {
        margin-top: 0;
        position: relative;
        right: 0;
    }

    .footer-menu ul {
        display: block;
        line-height: 2.5rem;
    }

    .login-form {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }

    .login-form-button {
        width: 100%;
    }

    .verification .ant-input {
        height: 65px !important;
        width: 55px !important;
    }

    /* ////////spacing */

    .xs-plr-1 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .xs-mt-0 {
        margin-top: 0rem !important;
    }

    .ant-layout-content {
        margin: 0 !important;
        padding: 1.5rem !important;
    }

    .mp0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .pt0 {
        padding-top: 0 !important;        
    }

    .app-membership .event-tab .ant-tabs-tab {
        padding: 5px 0.5rem !important;
    }

    .app-membership .ant-tabs-tab+.ant-tabs-tab {
        margin: 0 0 0 8px !important;
    }

    .app-membership .event-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 1rem !important;
    }

    .app-membership .event-tab .ant-tabs-tab-btn {
        font-size: 1rem !important;
    }

    .app-membership .ant-card {
        width: 100% !important;
    }

    .site-layout {
        margin-left: 0 !important;
    }

    .ant-layout-sider {
        /* display: none; */
    }
    .card-cover {
        height: 180px!important;
    }
    .cont-btn{
        width:"100%!important"
    }
    .event-tab .ant-tabs-tab {
        padding: 12px 1rem;
        justify-content: center;
      }
      .people-list ul {
        height: auto!important;
      }
      .dn {
        display: block!important;
    }
}

@media only screen and (max-width: 600px) {
    .event-tab .ant-tabs-tab-btn {
        font-size: 14px !important;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        margin: 0 0 0 5px!important;
      }
  }